import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { WithSGB4 } from "../common/components/WithSGB4";
import WidgetTracker from "../common/components/WidgetTracker";
import { SavedPersonContact } from "../common/typings/contacts.typings";
import ContactCreatorContextProvider from "../ic-contact-creator/contextProvider/ContactCreatorContextProvider";
import ContactEditorContent from "./contact-editor-content";
import { FLAG_ICON_CSS_FILE } from "../common/constants";
import { ReactAPI } from "../ic-contact-creator/ic-contact-creator";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import AppIntlProvider from "../common/components/intl/AppInltProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "@ic-anywhere/ic-dal";

const TAG = "ic-contact-editor";

interface Props {
  contactId: string;
  isHideButtons?: string;
  positionChange?: string;
  language?: string;
  personInfoReadOnly?: string;
  onEdited: (contact: SavedPersonContact) => void;
  onCancel?: () => void;
  onError?: (message?: string) => void;
}

const IcContactEditorWidget = React.forwardRef<ReactAPI, Props>(
  (
    { onEdited, onCancel, onError, contactId, isHideButtons, positionChange, language, personInfoReadOnly }: Props,
    ref
  ) => {
    const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

    return (
      <AppIntlProvider language={language}>
        <WithSGB4 extraUrls={[FLAG_ICON_CSS_FILE]}>
        <QueryClientProvider client={queryClient} >
          <ContactCreatorContextProvider>
            <ContactEditorContent
              onEdited={onEdited}
              onCancel={onCancel}
              onError={onError}
              isHideButtons={convertToBoolOrUndefined(isHideButtons) ?? false}
              positionChange={convertToBoolOrUndefined(positionChange)}
              contactId={contactId}
              personInfoReadOnly={convertToBoolOrUndefined(personInfoReadOnly)}
              ref={ref}
            />
          </ContactCreatorContextProvider>
          <WidgetTracker widgetName={TAG} />
          </QueryClientProvider>
        </WithSGB4>
      </AppIntlProvider>
    );
  }
);

widgetize(
  TAG,
  IcContactEditorWidget,
  {
    
    contactId: WidgetPropsMapping.asString(),
    isHideButtons: WidgetPropsMapping.asString(),
    language: WidgetPropsMapping.asString(),
    positionChange: WidgetPropsMapping.asString(),
    personInfoReadOnly: WidgetPropsMapping.asString(),
    onEdited: WidgetPropsMapping.asEventEmitter("edited"),
    onError: WidgetPropsMapping.asEventEmitter("error"),
    onCancel: WidgetPropsMapping.asEventEmitter("cancel"),
  },

  { neverInjectGlobalCss: true, exportReactAPI: { saveContact: "Save contact" } }
);
