import * as React from "react";
import { useState } from "react";
import { SavedPersonContact } from "../common/typings/contacts.typings";
import PersonalInformationForm, {
  mapAccountAddressToContactAddress,
} from "../ic-contact-creator/Form/PersonalInformationForm";
import AdditionalInformationForm from "../ic-contact-creator/Form/AdditionalInformationForm";
import ConfidentialityForm from "../ic-contact-creator/Form/ConfidentialityForm";
import { Form, Formik } from "formik";
import { validationSchema } from "../ic-contact-creator/Form/validation";
import { getContactById } from "../api/contacts/contacts.api";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../common/sgConnectScopes";
import Loading from "../common/components/Loading/Loading";
import { Button } from "@sgbs-ui/core";
import { ContactDbDto, SearchContactDbProperties } from "../api/contacts/contacts.typings";
import {
  ContactCreatorContext,
  ContactCreatorContextProviderProps,
} from "../ic-contact-creator/contextProvider/ContactCreatorContextProvider";
import ContactCreatorModals from "../ic-contact-creator/Modals/ContactCreatorModals";
import {
  mapToEditContactModel,
  mapToEmailsWithoutJustifications,
} from "../ic-contact-creator/contextProvider/contactCreator.mapper";
import { ContactCreatorFormModel, Email as CommonEmail, ModalType } from "../common/typings";
import { isEmpty, map, reduce } from "lodash-es";
import { ReactAPI } from "../ic-contact-creator/ic-contact-creator";
import { getClientById } from "../api/accounts/accounts.api";
import { mapToAccountWidget } from "../common/mappers/account.mapper";
import { useIntl } from "react-intl";
import { useRequestJobTypesReferentialQuery } from "../api/referentials/useRequestReferential";

const FULL_CONTACT_DB_PROPERTIES: SearchContactDbProperties[] = [
  "account",
  "jobTitle",
  "jobType",
  "title",
  "civility",
  "emails",
  "country",
  "isInternal",
  "internalHrTeam",
  "addresses",
  "phones",
  "isPepOrSpo",
  "visibility",
  "owners",
];

interface Props {
  contactId: string;
  contactsConfTop?: boolean;
  isHideButtons?: boolean;
  positionChange?: boolean;
  personInfoReadOnly?: boolean;
  onEdited: (contact: SavedPersonContact) => void;
  onCancel?: () => void;
  onError?: (message?: string) => void;
}

// eslint-disable-next-line react/display-name
const ContactEditorContent = React.forwardRef<ReactAPI, Props>(
  (
    {
      contactId,
      contactsConfTop,
      isHideButtons = false,
      positionChange = true,
      personInfoReadOnly = false,
      onEdited,
      onCancel,
      onError,
    }: Props,
    ref
  ) => {
    const [contact, setContact] = useState<ContactDbDto>({ id: "" } as ContactDbDto);
    const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;
    const { actions, context } = React.useContext(ContactCreatorContext) as ContactCreatorContextProviderProps;
    const {
      countries,
      isLoading,
      saveError,
      contact: SavedContact,
      etag,
      endPositionsError,
      emailCheckError,
    } = context;
    const [originalValues, setOriginalValues] = React.useState<ContactCreatorFormModel | undefined>(undefined);
    const [isLoadingJobTypesReferentials, _, jobTypesReferentials] = useRequestJobTypesReferentialQuery(fetch);

    const { formatMessage } = useIntl();

    const initialValues = React.useMemo(() => {
      return mapToEditContactModel(contact, countries, jobTypesReferentials);
    }, [contact, countries, jobTypesReferentials]);

    const existingEmails = React.useMemo(() => {
      return reduce(contact?.emails, (prev, acc) => ({ ...prev, [acc.value]: acc }), {});
    }, [contact]);

    React.useEffect(() => {
      setOriginalValues(initialValues);
    }, [initialValues]);

    const getInitialIdentification = (email: CommonEmail): CommonEmail => {
      if (existingEmails[email.value]) {
        return {
          ...email,
          personalEmailJustificationId:
            existingEmails[email.value].personalEmailJustificationId ?? email.personalEmailJustificationId,
        };
      }
      return { ...email };
    };

    const onSuccessCheckEmails = (): void => {
      actions.setModalType(ModalType.EditContactPositionChange);
    };

    const handleOnSubmit = (values: ContactCreatorFormModel): void => {
      const mainEmail = getInitialIdentification(values.mainEmail);
      const emails = map(values.emails, getInitialIdentification) ?? [];
      const typeCivility = values.civility === "Group" ? values.civility : "Person";

      if (
        (values.jobType !== originalValues?.jobType ||
          values.jobTitle !== originalValues?.jobTitle ||
          values.employeeOfAccountId !== originalValues?.employeeOfAccountId ||
          values.mainEmail !== originalValues?.mainEmail) &&
        positionChange
      ) {
        if (!values.employeeOfAccountId) {
          return;
        }
        const emailsToCheck = [mainEmail, ...emails].filter(e => !existingEmails[e.value]);
        if (isEmpty(emailsToCheck)) {
          onSuccessCheckEmails();
        } else {
          actions.checkEmailDetails(
            emailsToCheck,
            values.employeeOfAccountId,
            typeCivility,
            values.lastName,
            values.firstName,
            undefined,
            onSuccessCheckEmails
          );
        }
      } else {
        const editedContact = { ...values, mainEmail, emails };
        actions.editContact(contactId, editedContact, etag, () => fetchContact(contactId));
      }
    };

    const fetchContact = (contactId: string): void => {
      getContactById(fetch, contactId, [...FULL_CONTACT_DB_PROPERTIES, "person"])
        .then(result => setContact({ ...result, emails: mapToEmailsWithoutJustifications(result.emails) }))
        .catch(() => null);
    };

    React.useEffect(() => {
      fetchContact(contactId);
    }, [fetch, contactId]);

    React.useEffect(() => {
      if (SavedContact) {
        onEdited?.(SavedContact);
      }
    }, [SavedContact, onEdited]);

    React.useEffect(() => {
      if (saveError) {
        onError?.(saveError.userMessage ?? saveError.message ?? "The request has failed. Please try again.");
      }
    }, [saveError, onError]);

    React.useEffect(() => {
      if (emailCheckError && emailCheckError.status !== 428) {
        onError?.(
          emailCheckError.userMessage ?? emailCheckError.message ?? "The request has failed. Please try again."
        );
      }
    }, [emailCheckError, onError]);

    React.useEffect(() => {
      if (endPositionsError) {
        onError?.("Could not end some positions, pleasy try to end them manually");
      }
    }, [endPositionsError, onError]);

    if (isEmpty(contact.addresses) && contact.employeeOfAccountId) {
      getClientById(fetch, contact.employeeOfAccountId, { properties: ["addresses", "mainAddress"] })
        .then(r => {
          setContact({ addresses: [mapAccountAddressToContactAddress(mapToAccountWidget(r))], ...contact });
        })
        .catch(() => null);
    }

    return (
      <Formik
        {...{
          initialValues,
          onSubmit: handleOnSubmit,
          validationSchema,
        }}
        enableReinitialize={true}
      >
        {({ submitForm }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useImperativeHandle(
            ref,
            () => ({
              saveContact() {
                submitForm();
              },
            }),
            []
          );
          return (
            <Form>
              <ContactCreatorModals isEdit={true} contactId={contactId} contact={contact} />
              <PersonalInformationForm personInfoReadOnly={personInfoReadOnly} />
              <AdditionalInformationForm />
              <ConfidentialityForm />
              <div className="mt-4 col-12 p-0">
                <Loading isLoading={isLoading} />
                {!isHideButtons && (
                  <div className="d-flex flex-row-reverse">
                    <Button
                      size="lg"
                      className="ml-3"
                      type="submit"
                      text={formatMessage({
                        id: "global.save",
                        defaultMessage: "Save",
                      })}
                      disabled={isLoading}
                    />

                    <Button
                      btnType="flat"
                      color="secondary"
                      size="lg"
                      text={formatMessage({
                        id: "global.cancel",
                        defaultMessage: "Cancel",
                      })}
                      disabled={isLoading}
                      onClick={onCancel}
                    />
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
);

export default ContactEditorContent;
